<template>
  <div class="content">
    <div class="center">
      <div class="top">
        <el-button
          round
          class="go_back"
          icon="el-icon-back"
          @click="goBack"
        ></el-button>
        <h1 class="top_h1">论文改重小工具</h1>
        <span class="space-20"></span>
        <el-popover
          class="popover"
          placement="bottom"
          title="关于疑问 "
          width="200"
          trigger="hover"
          content="这是论文改重小工具（内测版）！建议使用一级到二级就好，并不是等级越高越好，等级越高，意味着改变的东西越离谱，也就越有可能会脱离本意。注意：当前等级功能尚未开放！"
        >
          <i slot="reference" class="el-icon-question"></i>
        </el-popover>
        <el-select
          class="select"
          v-model="value"
          :disabled="disabled"
          placeholder="请选择改写等级"
          @change="changeSelect"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="help"></div>
      <el-input
        type="textarea"
        placeholder="请输入内容"
        v-model="textarea"
        :maxlength="maxlength"
        show-word-limit
        resize="none"
        rows="7"
        class="textarea"
        @input="input"
        clearable
      >
      </el-input>
      <el-divider content-position="right"
        ><el-button type="success" round @click="clearnInput"
          >清空输入</el-button
        >
        <el-button type="primary" round @click="ToDotranslate"
          >开始转换</el-button
        >
      </el-divider>

      <el-input
        v-loading="isLoding"
        type="textarea"
        placeholder="请输入内容"
        v-model="textarea1"
        rows="7"
        resize="none"
        class="textarea"
      >
      </el-input>
    </div>
  </div>
</template>

<script>
import { MD5 } from "@/tools/md5";
export default {
  data() {
    return {
      textarea: "",
      lastTextarea: "",
      textarea1: "",
      maxlength: 500,
      language: ["en", "ru", "kor", "jp"],
      isLoding: false,
      options: [
        {
          value: 1,
          label: "一级",
        },
        {
          value: 2,
          label: "二级",
        },
        {
          value: 3,
          label: "三级",
        },
        {
          value: 4,
          label: "四级",
        },
        {
          value: 5,
          label: "五级",
        },
      ],
      value: 1,
      lastValue: 0,
      disabled: false,
    };
  },
  methods: {
    // event input
    input(value) {
      if (value.length == this.maxlength) {
        this.$message({
          showClose: true,
          duration: 6000,
          message: `只能输入 ${this.maxlength} 个字符哦！`,
          type: "warning",
        });
      }
    },
    clearnInput() {
      this.textarea = "";
    },
    async doTrans() {
      this.isLoding = true;
      let query = this.textarea;
      const data = { query, times: 1 };
      const res = await this.$store.dispatch("trans/doTrans", data);
      // console.log(res);
      this.textarea1 = res.results.res;
      // this.lastValue = this.value;
      this.lastTextarea = this.textarea;
      this.isLoding = false;
    },

    async translate() {
      let query = this.textarea;
      const appid = this.$store.getters.appid;
      const key = this.$store.getters.key;
      let str1 = appid + query + new Date().getTime() + key;
      let sign = MD5(str1);
      const params = {
        q: query,
        appid,
        salt: new Date().getTime(),
        from: "zh",
        to: "en",
        sign,
      };
      const { trans_result } = await this.$store.dispatch(
        "trans/doTrans",
        params
      );
      params.from = params.to;
      params.to = "zh";
      params.q = trans_result[0].dst;
      params.salt = new Date().getTime();
      str1 = appid + params.q + new Date().getTime() + key;
      params.sign = MD5(str1);
      console.log(111);
      const data = await this.$store.dispatch("user/translate", params);
      this.textarea1 = data.trans_result[0].dst;
      this.lastValue = this.value;
      this.lastTextarea = this.textarea;
    },
    changeSelect() {
      console.log(this.value);
    },
    ToDotranslate() {
      if (!this.textarea) {
        this.$message({
          showClose: true,
          duration: 3000,
          message: `请先输入内容`,
          type: "warning",
        });
        return;
      }
      if (this.textarea == this.lastTextarea) {
        this.$message({
          showClose: true,
          duration: 3000,
          message: `和上次转换的内容一样哦，请更改内容或改变转换等级~`,
          type: "warning",
        });
        return;
      }
      this.textarea1 = "";
      // if (this.value != 1) {
      //   this.translateTest();
      // } else {
      //   this.translate();
      // }
      this.doTrans();
    },
    goBack() {
      this.$router.go(-1);
    },
    async translateTest() {
      let query = this.textarea;
      const appid = this.$store.getters.appid;
      const key = this.$store.getters.key;
      let str1 = appid + query + new Date().getTime() + key;
      let sign = MD5(str1);
      const params = {
        q: query,
        appid,
        salt: new Date().getTime(),
        from: "zh",
        to: "en",
        sign,
      };
      let reback = "";
      for (let i = 0; i < this.value; i++) {
        let from = i == 0 ? "zh" : params.to;
        params.q = i != 0 ? reback : this.textarea;
        if (i < this.value - 1) {
          params.from = from;
          str1 = appid + params.q + new Date().getTime() + key;
          params.sign = MD5(str1);
          params.to = this.language[i];
          params.salt = new Date().getTime();
          const { trans_result } = await this.$store.dispatch(
            "user/translate",
            params
          );
          reback = trans_result[0].dst;
          console.log(reback);
          console.log(params);
        } else {
          params.from = from;
          params.to = "zh";
          params.salt = new Date().getTime();
          str1 = appid + reback + new Date().getTime() + key;
          params.sign = MD5(str1);
          params.q = reback ? reback : params.q;
          const { trans_result } = await this.$store.dispatch(
            "user/translate",
            params
          );
          this.textarea1 = trans_result[0].dst;
          this.lastValue = this.value;
          this.lastTextarea = this.textarea;
        }
      }
    },
  },
};
</script>

<style scoped>
@import "../../assets/CSS/common.css";
.content {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.top {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  color: skyblue;
}
.top .top_h1 {
  text-align: center;
  display: inline-block;
}
.textarea {
  margin: 20px 0 20px 0;
  font-size: 20px;
}
.help {
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
}
.popover {
  margin: 10px 0 0 0;
}
.select {
  position: absolute;
  right: 0px;
}

.go_back {
  position: absolute;
  left: 0px;
}
</style>